.navBar .nav-link:hover{
  background-color:ligh;
  border-radius: 5%;
}

.grid-wrapper {
  height: 100%;
  padding-top: 26px;
  box-sizing: border-box;
}

.button-bar {
  position: absolute;
  top: 0;
  left: 0;
  margin: -3px;
}
